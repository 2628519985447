<template>
  <div>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="editEmployeeProfileConfirm" />
    <base-snackbar-notification ref="editEmployeeProfileSnackbar" />
    <v-card elevation="0">
      <v-card-text>
        <v-form ref="userForm" @submit.prevent="save()" class="mb-4">
            <v-row class="align-center mb-5 ml-1 mt-1">
              <v-avatar v-if="userModel.profilePicture" size="50">
                <v-img :src="userModel.profilePicture"></v-img>
              </v-avatar>
              <v-avatar v-else size="50">
                <v-img src="../../assets/avatar.png"></v-img>
              </v-avatar>
              <span class="ml-4 text-h3 black--text">{{ userModel.firstName }} {{ userModel.lastName }}</span>
            </v-row>

          <v-expansion-panels class="mb-8">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>Personal Details</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pt-4">
                  <v-col md="6" class="py-0">
                    <v-text-field
                      label="First Name"
                      v-model="userModel.firstName"
                      outlined
                      class="text-body-1"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      label="Last Name"
                      v-model="userModel.lastName"
                      outlined
                      hint
                      class="text-body-1"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="12" class="py-0">
                    <mobile-number-input
                        @updateMobileNumberInput="mobileNumberFunc"
                        :allowTestNumbers="true"
                        :initialValue="userModel.telNumber"
                        class="text-body-1 py-0"
                    />
                    </v-col>
                </v-row>
                <v-row>
                  <v-col md="12" class="py-0">
                    <v-text-field
                      label="Email"
                      v-model="userModel.email"
                      outlined
                      class="text-body-1"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" v-if="showIdNumberPassportSelect" class="py-0">
                  <base-identification-type-picker
                      :initial="'South African ID'"
                      label="Identification Type"
                      outlined
                      :chosen-identification-types.sync="idType"
                      dense
                  />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" v-if="idType == 'South African ID'" class="py-0">
                    <v-text-field
                      label="ID Number"
                      v-model="userModel.idNumber"
                      outlined
                      class="text-body-1"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="py-0" v-else>
                    <v-text-field
                      label="Passport Number"
                      v-model="userModel.passport"
                      outlined
                      class="text-body-1"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="py-0">
                    <v-textarea
                      label="Address"
                      outlined
                      counter
                      v-model="userModel.fullAddress"
                      class="text-body-1"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>Payment Details</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pt-4">
                  <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    label="Account Holder Name"
                    v-model="userModel.accountHolder"
                    outlined
                    class="text-body-1"
                    dense
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                      label="Account Number"
                      v-model="userModel.accountNumber"
                      outlined
                      class="text-body-1"
                      dense
                  ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <base-bank-picker
                        :chosen-bank.sync="userModel.bankName"
                        :initial="userModel.bankName"
                        label="Bank Name"
                        outlined
                        class="text-body-1"
                        dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                  <base-bank-branch-picker
                      :chosen-bank-branch.sync="userModel.branchCode"
                      :initial="userModel.branchCode"
                      label="Branch Code"
                      outlined
                      class="text-body-1"
                      dense
                  />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <base-account-type-picker
                        :chosenAccountType.sync="userModel.accountType"
                        :initial="userModel.accountType"
                        label="Account Type"
                        required
                        outlined
                        hint="Capitec, Tyme and Discovery bank are Savings"
                        :rules="[(v) => !!v || 'Account type is required']"
                        dense
                        class="text-body-1"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="px-0">
              <v-expansion-panel-header>
                <h3>Contract Details</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0">
                <v-row class="pt-0">
                  <base-contract-form
                      ref="employeeEditContractForm"
                      v-bind:edit="true"
                      v-bind:showActions="false"
                      :userId="this.userModel.id"
                      class="pt-0"
                  />
<!--                  <v-col cols="12" md="12">-->
<!--                    <div v-if="userModel.contractHtml">-->
<!--                      <v-btn-->
<!--                        elevation="1"-->
<!--                        :href="userModel.contractHtml"-->
<!--                        target="_blank"-->
<!--                        class="my-4"-->
<!--                      >-->
<!--                        View full screen and download-->
<!--                      </v-btn>-->
<!--                      <pdf-->
<!--                        style="border: 1px solid #333"-->
<!--                        :src="userModel.contractHtml"-->
<!--                        @num-pages="pageCount = $event"-->
<!--                        @page-loaded="currentPage = $event"-->
<!--                      ></pdf>-->
<!--                      <v-col cols="6">-->
<!--                        {{ currentPage }} / {{ pageCount }}-->
<!--                      </v-col>-->
<!--                    </div>-->
<!--                    <div v-else>-->
<!--                      <h3 class="my-1">Contract preview</h3>-->
<!--                      <p>-->
<!--                        Employee has not requested the contract yet, Once they-->
<!--                        request it you will see it here-->
<!--                      </p>-->
<!--                    </div>-->
<!--                  </v-col>-->
                </v-row>
              </v-expansion-panel-content></v-expansion-panel
            >
          </v-expansion-panels>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" class="" elevation="0" :loading="saveButtonLoading">
                Save
              </v-btn>
              <v-btn color="warning" outlined class="ml-4" @click="resetPassword()">
                Reset Password
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { User } from "@/models/User";
import { gql } from "apollo-boost";
import defaultProfile from "../../assets/avatar.png";
import pdf from "vue-pdf";
import MobileNumberInput from '@/components/Base/MobileNumberInput.vue'

export default {
  components: { pdf, MobileNumberInput },
  name: "Edit Employee",
  data() {
    return {
      isEdit: false,
      isLoading: false,
      saveButtonLoading: false,
      errorMessage: '',
      isSuccess: false,
      successMessage: '',
      activePicker: null,
      date: null,
      currentPage: 0,
      pageCount: 0,
      idType: "South African ID",
      userModel: {
        id: "",
        firstName: "",
        lastName: "",
        telNumber: "",
        email: "",
        role: "employee",
        idNumber: "",
        passport: "",
        accountNumber: "",
        accountHolder: "",
        accountType: "",
        fullAddress: "",
        profilePicture: "",
        bankName: "",
        branchCode: "",
        workAddress: "",
        jobTitle: "",
      },
      menu: false,
      search: "",
      dialog3: false,
      totalRecords: 0,
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  mounted() {
    this.$emit("update:pageName", this.$options.name)
  },
  computed: {
    showIdNumberPassportSelect() {
      if (this.$_.isEmpty(this.userModel.idNumber)) {
        this.idType = "Passport"
      } else {
        this.idType = "South African ID"
      }
      return (this.$_.isEmpty(this.userModel.idNumber) && this.$_.isEmpty(this.userModel.passport))
    },
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
            role
            fullAddress
          }
        }
      `,
      fetchPolicy: "network-only",
      result() {
        if (this.me.id !== "" || null) {
          this.isEdit = true;
          this.id = this.me.id;
        }
      },
    },

    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            email
            telNumber
            fullAddress
            profilePicture
            idNumber
            passport
            bankName
            branchCode
            accountType
            accountNumber
            accountHolder
            role
            workAddress
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.isEdit = true;
        this.userModel.id = this.user.id;
        this.userModel.role = this.user.role;
        this.userModel.firstName = this.user.firstName;
        this.userModel.lastName = this.user.lastName;
        this.userModel.telNumber = this.user.telNumber;
        this.userModel.email = this.user.email;
        this.userModel.idNumber = this.user.idNumber;
        this.userModel.fullAddress = this.user.fullAddress;
        this.userModel.passport = this.user.passport;
        this.userModel.bankName = this.user.bankName;
        this.userModel.branchCode = this.user.branchCode;
        this.userModel.accountType = this.user.accountType;
        this.userModel.accountNumber = this.user.accountNumber;
        this.userModel.accountHolder = this.user.accountHolder;
        this.userModel.workAddress = this.user.workAddress;
      },
    }
  },
  methods: {
    mobileNumberFunc(value) {
      this.userModel.telNumber = value
    },
    async resetPassword() {
      const self = this;
      const dialogAnswer = await this.$refs.editEmployeeProfileConfirm.open("Reset Password", "Are you sure you want to reset this employees password? They will receive an SMS with instructions on what to do.", "warning", "yesno")
      if (!dialogAnswer) return
      self.isLoading = true;
      this.$apollo.mutate({
        mutation: gql`
          mutation ($telNumber: String!) {
            getResetPasswordLink(telNumber: $telNumber)
          }
        `,
        variables: {
          telNumber: this.user.telNumber,
        },
      }).then((response) => {
        this.$refs.editEmployeeProfileConfirm.open('Success', `Password Reset Link sent to the employee's mobile phone: ${this.user.telNumber}`, 'success')
        self.isLoading = false;
      }).catch((error) => {
        self.errorMessage = _.get(error, 'graphQLErrors[0].message', `Sorry, there was a system error. Please try again or contact support. ${error}`);
        this.$refs.editEmployeeProfileConfirm.open('Error', self.errorMessage, 'error')
        self.isLoading = false;
      })
    },
    defaultProfileImage() {
      return defaultProfile;
    },
    async save() {
      let saveContractError = false
      this.saveButtonLoading = true
      if (this.$refs.employeeEditContractForm) {
        const saveContract = await this.$refs.employeeEditContractForm.save().catch((e) => { return e})
        if (saveContract instanceof Error) {
          // Error handled by the ContractForm Component
          this.saveButtonLoading = false
          saveContractError = true
        }
      }
      this.isLoading = true;
      const self = this;
      if (!self.$refs.userForm.validate()) {
        this.$refs.editEmployeeProfileConfirm.open("Error", "There was a problem with the information you provided. Please check the form and try again.", "error")
        self.isLoading = false;
        self.saveButtonLoading = false
        return;
      }

      if (self.isEdit) {
        await this.updateUser()
          .then(() => {
            this.isLoading = false;
            this.saveButtonLoading = false
            if (!saveContractError) {
              this.$refs.editEmployeeProfileSnackbar.open('The details have been saved.', 'success')
            }
          })
          .catch((e) => {
            this.isLoading = false;
            this.saveButtonLoading = false
            console.error("Problem updating", e);
            this.$refs.editEmployeeProfileConfirm.open("Error", "There was an error saving the Employee. Please try again or contact support.", "error")
          });
      }
      this.saveButtonLoading = false
      this.isLoading = false;
    },
    async updateUser() {
      const self = this;
      await self.$apollo.mutate({
          mutation: gql`
            mutation updateUser($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.$route.params.id,
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              telNumber: self.userModel.telNumber,
              email: self.userModel.email,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              bankName: self.userModel.bankName,
              branchCode: self.userModel.branchCode,
              accountHolder: self.userModel.accountHolder,
              accountNumber: self.userModel.accountNumber,
              accountType: self.userModel.accountType,
              role: "employee",
              workAddress: self.userModel.workAddress,
            },
          },
      }).then((response) => {
        return (true)
      }).catch((error) => {
        self.errorMessage = _.get(error, 'graphQLErrors[0].message', `Sorry, there was a system error. Please try again or contact support. ${error}`);
        throw new Error(self.errorMessage)
      })
    },
    async createUser() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.userModel.firstName,
              lastName: self.userModel.lastName,
              telNumber: self.userModel.telNumber,
              bankName: self.userModel.bankName,
              branchCode: self.userModel.branchCode,
              accountHolder: self.userModel.accountHolder,
              accountNumber: self.userModel.accountNumber,
              accountType: self.userModel.accountType,
              email: self.userModel.email,
              idNumber: self.userModel.idNumber,
              passport: self.userModel.passport,
              fullAddress: self.userModel.fullAddress,
              workAddress: self.userModel.workAddress,
              role: "employee",
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the User!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createUser) {
          self.userModel.id = response?.data?.createUser?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
